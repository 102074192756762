import React, { useRef, useCallback } from 'react';
import { Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SnackbarProvider } from 'notistack';
import App from './App';
import OktaLogin from './pages/OktaLogin';
// import LoginCallback from './components/auth/LoginCallback'
import SecureRoute from './components/auth/SecureRoute'
import MagicLogin from './pages/MagicLogin'
import ClientPortal from './pages/ClientPortal'
import ClientApp from './ClientApp';
import config from './config.json'

const oktaAuthObj = new OktaAuth({
    issuer: config.oktaConfig.issuer,
    clientId: config.oktaConfig.clientId,
    originalUri: window.location.origin,
    redirectUri: window.location.origin + '/authcallback',
    responseType: ['id_token', 'token'],
    scopes: ['openid', 'email', 'profile', 'groups', 'appdata', 'offline_access'],
    pkce: false
});

oktaAuthObj.tokenManager.on("added", (key, newToken) => {
    console.log(`OKTA token added`)
})

oktaAuthObj.tokenManager.on("error", (error) => {
    console.log('OKTA ERROR', JSON.stringify(error))
})
oktaAuthObj.tokenManager.on("expired", (key, token) => {
    console.log("OKTA token has expired")
})

oktaAuthObj.tokenManager.on('renewed', function (key, newToken, oldToken) {
    console.log("OKTA renewed----", newToken, ('accessToken' in newToken));
});

const baseUrl = config.oktaConfig.issuer.replace(/\/oauth2\/.+/, '')

const AppRoutes = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const redirected = useRef()

    const onAuthRequired = useCallback(() => {
        console.log("LOGIN REQUIRED", pathname, pathname.includes('ml/', 0))
        if (pathname !== '/login' && pathname !== '/authcallback' && !pathname.includes('client/', 0) && !redirected.current) {
            // authService.setFromUri(pathname)
            navigate('/login')
            redirected.current = true
        }
    }, [navigate, pathname])

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        console.log('originaluri:', originalUri)
        console.log('window.l.o:', window.location.origin)
        navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true })
    };

    // const onSessionExpired = useCallback(() => {
    //     console.log("EXPIRED")
    //     if (pathname !== '/login' && pathname !== '/authcallback' && !pathname.includes('viewer/', 0) && !redirected.current) {
    //         // authService.setFromUri(pathname)
    //         navigate('/login')
    //         redirected.current = true
    //     }
    // }, [navigate, pathname])

    return (
        <SnackbarProvider maxSnack={10} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} autoHideDuration={6000}>
            <Security
                oktaAuth={oktaAuthObj}
                onAuthRequired={onAuthRequired}
                // onSessionExpired={onSessionExpired}
                restoreOriginalUri={restoreOriginalUri}
            >
                <Routes>
                    <Route path='/login' element={<OktaLogin baseUrl={baseUrl} />} />
                    <Route path='/authcallback' Component={LoginCallback} />
                    <Route path="/client" element={<Outlet />}>
                        <Route path=':sessionId/' Component={ClientApp} />
                        <Route path="project/:projectId/" Component={ClientPortal} />
                        <Route path='login/:linkId/' Component={MagicLogin} />
                    </Route>
                    <Route path="*" element={<SecureRoute path="*" Component={App} />} />
                </Routes>
                {/* <Route path='/viewer/:sessionId' Component={ClientApp} />
            <SecureRoute Component={App} /> */}
            </Security>
        </SnackbarProvider>

    )
})

export default AppRoutes;