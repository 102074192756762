import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import config from '../../config.json';
import { AppBar, Toolbar, Typography, CircularProgress, Select, MenuItem, LinearProgress, Chip, ListSubheader, IconButton, Badge, Popover, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import MUIRichTextEditor from 'mui-rte-olive'
import { EditorState, convertToRaw } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ChatIcon from '@mui/icons-material/Chat';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { useChimeMessaging } from './hooks/chime';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
    InfiniteList,
    PopOverItem,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalButtonGroup,
    // ModalButton,
    ChatBubble,
    ChatBubbleContainer,
    EditableChatBubble,
    MessageAttachment,
    formatDate,
    formatTime,
    lightTheme,
    // darkTheme
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import KeyboardEventHandler from 'react-keyboard-event-handler'
import Files from 'react-butterfiles'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import emoji from 'remark-emoji'
import remarkExternalLinks from 'remark-external-links'
// import { gemoji } from 'gemoji'
import './chime.css';
import { cloneDeep } from 'lodash';
import FilterListIcon from '@mui/icons-material/FilterList';

const PREFIX = 'ChimeChat';

const classes = {
    root: `${PREFIX}-root`,
    header: `${PREFIX}-header`,
    nochannel: `${PREFIX}-nochannel`,
    messages: `${PREFIX}-messages`,
    input: `${PREFIX}-input`,
    inputText: `${PREFIX}-inputText`,
    chatInput: `${PREFIX}-chatInput`,
    chatActions: `${PREFIX}-chatActions`,
    sendButton: `${PREFIX}-sendButton`,
    attachButton: `${PREFIX}-attachButton`,
    attachText: `${PREFIX}-attachText`,
    attachLoading: `${PREFIX}-attachLoading`,
    chipList: `${PREFIX}-chipList`,
    chip: `${PREFIX}-chip`,
    loadingCover: `${PREFIX}-loadingCover`,
    iconSpacer: `${PREFIX}-iconSpacer`,
    selector: `${PREFIX}-selector`,
    channelSelector: `${PREFIX}-channelSelector`,
    channelSelectorText: `${PREFIX}-channelSelectorText`,
    channelSelectorIcon: `${PREFIX}-channelSelectorIcon`,
    channelText: `${PREFIX}-channelText`,
    unreadBadge: `${PREFIX}-unreadBadge`,
    channelItem: `${PREFIX}-channelItem`,
    channelSubIcon: `${PREFIX}-channelSubIcon`,
    notificationBox: `${PREFIX}-notificationBox`,
    popoverList: `${PREFIX}-popoverList`,
    sessionFilterOptions: `${PREFIX}-sessionFilterOptions`
};

const Root = styled('div')(({ theme, highlightArrow }) => ({
    // style for <Root> component
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`& .${classes.header}`]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        boxSizing: 'border-box',
        height: 'calc(5rem + 5px)'
    },
    [`& .${classes.nochannel}`]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        boxSizing: 'border-box',
        backgroundColor: '#f0f1f2'
    },
    [`& .${classes.messages}`]: {
        flex: 1,
        width: '100%',
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    [`& .${classes.chatActions}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '6px'
    },
    [`& .${classes.sendButton}`]: {
        height: '100%',
        // width: '2rem',
        // marginBottom: '6px'
    },
    [`& .${classes.attachButton}`]: {
        height: '100%',
        // width: '2rem',
        marginBottom: '6px'
    },
    [`& .${classes.attachText}`]: {
        margin: '0.125rem'
    },
    [`& .${classes.attachLoading}`]: {
        width: '100%',
    },
    [`& .${classes.chipList}`]: {
        width: '100%',
        minHeight: '1rem',
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '0 0.5rem 0 0.5rem',
        margin: 0,
        boxSizing: 'border-box'
    },
    [`& .${classes.chip}`]: {
        margin: theme.spacing(0.5),
    },
    [`& .${classes.loadingCover}`]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 20,
        backgroundColor: 'rgba(0,0,0,0.4)'
    },
    [`& .${classes.iconSpacer}`]: {
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        padding: '12px'
    },
    [`& .${classes.selector}`]: {
        margin: '0 auto 0 auto'
    },
    [`& .${classes.channelSelector}`]: {
        backgroundColor: 'transparent',
        ...theme.typography.h5,
        fontWeight: 800,
        '&:focus': {
            backgroundColor: 'transparent',
        }
    },
    [`& .${classes.channelSelectorText}`]: {
        color: 'rgba(255, 255, 255)'
    },
    [`& .${classes.channelSelectorIcon}`]: {
        stroke: 'white',
        width: '1.25em',
        height: '1.25em',
        top: 'auto',
        filter: highlightArrow ? 'drop-shadow(0px 0px 2px white)' : 'none',
        color: highlightArrow ? theme.palette.secondary.light : 'white'//'rgba(255, 255, 255, 0.54)'
    },
    [`& .${classes.channelText}`]: {
        fontWeight: 800
    },
    [`& .${classes.unreadBadge}`]: {
        // left: '0.5em',
        top: '50%'
    },
    [`& .${classes.channelItem}`]: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1em'
    },
    [`& .${classes.channelSubIcon}`]: {
        marginLeft: '0.5em'
    },
    [`& .${classes.notificationBox}`]: {
        cursor: 'pointer'
    },
    [`& .${classes.popoverList}`]: {
        minWidth: '200px',
        maxHeight: '450px'
    }
}));

const FilterRoot = styled('div')({
    [`& .${classes.sessionFilterOptions}`]: {
        listStyleType: 'none',
        marginTop: 0,
        padding: 0,
        minWidth: '200px',
        "& li": {
            paddingLeft: "20px",
            paddingTop: "6px",
            paddingBottom: "6px",
        },
        "& li:hover": {
            cursor: 'pointer',
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    }
});

const MessageRoot = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 1rem 0 1rem',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 12%)',
    [`& .${classes.inputText}`]: {
        flex: 1
    },
    [`& .${classes.chatInput}`]: {
        border: '1px solid #bdbdbd',
        borderRadius: '4px'
    },
    [`& .MUIRichTextEditor-root`]: {
        // height: '100px',
        border: '1px solid gray',
        borderRadius: '4px',
    },
    [`& .MUIRichTextEditor-toolbar`]: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid gray',
        padding: '5px'
    },
    [`& .MUIRichTextEditor-editor`]: {
        minHeight: '45px',
        width: '90%'
    },
    [`& .MUIRichTextEditor-editorContainer`]: {
        width: '90%'
    },
});

// const emojiAutocomplete = gemoji.map((x) => (
//     {
//         keys: x.tags,
//         value: x.emoji,
//         content: x.emoji
//     }
// ))

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const attachmentLocation = {
    bucket: config.chimeBucket,
    region: 'us-west-1'
}

const typeLabels = {
    group: 'Group Channels',
    private: 'Private Channels',
    user: 'User Channels'
}

const emailRegexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

const DismissBtn = (id) => {
    const { closeSnackbar } = useSnackbar();
    return <>
        <IconButton
            aria-label="close"
            color="inherit"
            // className={classes.close} //NO CLASS DEFINED
            onClick={() => closeSnackbar(id)}
        >
            <CloseIcon />
        </IconButton>
    </>
}

const ChimeChat = React.memo(({ getChatCredentials, chatInstance, chatChannels, messageUser, updateUsers, onStarted, onConnectionChange, onManageChannelGuests, onChannelMessage, visible, setOpen }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [attachments, setAttachments] = useState([])
    const [defaultValue, setDefaultValue] = useState()
    const [notificationsPopoverAnchor, setNotificationsPopoverAnchor] = useState();
    const [channelUsersPopoverAnchor, setChannelUsersPopoverAnchor] = useState();
    const [searchUser, setSearchUser] = useState('')
    const [channelUsers, setChannelUsers] = useState()
    const [filterUsersList, setFilterUsersList] = useState(null);
    const [showClients, setShowClients] = useState(true)
    const [showStaff, setShowStaff] = useState(true)

    const editorRef = useRef()
    const editorStateRef = useRef()
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    // const [showMessageAlert, setShowMessageAlert] = useState(false)
    // const [alertMessage, setAlertMessage] = useState({})

    const {
        started,
        connected,
        currentUser,
        channelList,
        currentChannel,
        channelMessages,
        loadingMessages,
        attachingFile,
        newChannels,
        unreadChannels,
        loadUserChannels,
        setCurrentChannel,
        getCurrentChannelUsers,
        // getUserChannels,
        adminJoinChannelById,
        updateChannelUsers,
        loadChannelMessages,
        sendChannelMessage,
        updateChannelMessage,
        redactChannelMessage,
        downloadMessageAttachment,
        createPrivateChannel,
        clearAllNotifications
    } = useChimeMessaging(getChatCredentials, chatInstance, attachmentLocation, visible)



    useEffect(() => {
        if (started && onStarted) onStarted()
    }, [started, onStarted])

    useEffect(() => {
        if (onConnectionChange) onConnectionChange(connected)
    }, [connected, onConnectionChange])

    useEffect(() => {
        if (onChannelMessage) onChannelMessage(newChannels.length + unreadChannels.length)
    }, [newChannels, unreadChannels, onChannelMessage])

    const handleToastClick = useCallback((id) => {
        const channel = channelList.find(x => x.id === id)
        setCurrentChannel(channel)
        if (!visible) setOpen({ open: true });
        closeSnackbar(channel.id);
        // setShowMessageAlert(false)
        // setAlertMessage({})
    }, [channelList, setCurrentChannel, visible, setOpen, closeSnackbar])

    useEffect(() => {
        if (newChannels.length && channelList) {
            const channelInfo = channelList.reduce((p, c) => (p[c.ChannelArn] = { name: c.Name, private: c.type === 'user' }, p), {})
            // Check if newChannels arn exists in channelList

            const isExistingChannel = newChannels.filter(arn => !!channelInfo[arn])
            if (isExistingChannel.length) {
                // Get channel info using arn
                const channel = channelList.find(x => x.ChannelArn === isExistingChannel[0])
                if (channel) {
                    // setShowMessageAlert(true)
                    enqueueSnackbar(<span onClick={() => handleToastClick(channel.id)}>{`You have been added to a new chat channel: "${channel.Name} ${channel.CreatedBy.Name}"`}</span>,
                        {
                            key: channel.id, preventDuplicate: true, action: DismissBtn, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }
                        })
                    // setAlertMessage({ id: channel.id, message: `You have been added to a new chat channel: "${channel.Name} ${channel.CreatedBy.Name}"` })
                }
            }
        }
    }, [newChannels, channelList, enqueueSnackbar, handleToastClick])

    const joinChannels = useCallback(async (channels) => {
        if (currentUser.isAdmin) {
            const channelJoin = channels.map(x => adminJoinChannelById(x.channelId))
            return await Promise.all(channelJoin).catch(() => {
                console.log("UNABLE TO JOIN CHANNELS")
                return {}
            })
        } else {
            return Promise.resolve({})
        }
    }, [currentUser, adminJoinChannelById])

    const openPrivateChannel = useCallback(async (userId, username, techCheck) => {
        setChannelUsersPopoverAnchor(null);
        console.log('OPENING CHANNEL FOR', userId, username, techCheck)
        //let userChannel = (channelList || []).find(x => x.userId === userId && !!x.techCheck === !!techCheck)
        let userChannel = channelList?.find(channel => channel.type === 'user' && channel.users?.[userId])
        console.log('USER CHANNEL???', userChannel)
        if (!userChannel) {
            userChannel = await createPrivateChannel(userId, username, techCheck)
            // setChannelList(prev => {
            //     const newChannels = { ...prev }
            //     newChannels.user = [...(newChannels.user || []), userChannel]
            //     return newChannels
            // })
        }
        setCurrentChannel(userChannel)
    }, [channelList, createPrivateChannel, setCurrentChannel])

    if (messageUser) messageUser.current = openPrivateChannel

    if (updateUsers) updateUsers.current = updateChannelUsers

    // useEffect(() => {
    //     if (started && chatChannels?.length) {
    //         joinChannels(chatChannels).then(getUserChannels).then((channels) => {
    //             console.log('Got user channels', channels, chatChannels)
    //             channels.sort((a, b) => (
    //                 Object.keys(typeLabels).indexOf(a.type) - Object.keys(typeLabels).indexOf(b.type)
    //             ))
    //             const groupedChannels = channels.reduce((p, c) => (!!p[c.type] ? p[c.type].push(c) : p[c.type] = [c], p), {})
    //             setChannelList(groupedChannels)
    //             const defaultChannelId = (chatChannels.find(x => x.default) || chatChannels[0]).channelId
    //             const defaultChannelData = channels.find(x => x.id === defaultChannelId)
    //             setCurrentChannel(channel => channel || defaultChannelData)
    //         })
    //     }
    // }, [started, chatChannels, joinChannels, getUserChannels])

    useEffect(() => {
        if (started && Array.isArray(chatChannels)) {
            joinChannels(chatChannels).then(loadUserChannels)
        }
    }, [started, chatChannels, joinChannels, loadUserChannels])

    useEffect(() => {
        if (channelList && Array.isArray(chatChannels)) {
            console.log('Got user channels', channelList)
            const defaultChannel = (chatChannels.find(x => x.default) || chatChannels[0])
            const defaultChannelData = defaultChannel ? channelList.find(x => x.id === defaultChannel.channelId) : channelList[0]
            setCurrentChannel(channel => (channel && channelList.find(x => x.id === channel.id)) || defaultChannelData)
        }
    }, [channelList, chatChannels, setCurrentChannel])

    // useEffect(() => {
    //     if (currentChannel?.type === "user") {
    //         getCurrentChannelUsers().then((guests) => {
    //             setCurrentChannelUsers(guests)
    //         })
    //     } else {
    //         setCurrentChannelUsers(null)
    //     }
    // }, [currentChannel])

    const showUsers = useMemo(() => {
        return (chatChannels || []).reduce((p, c) => (p[c.channelId] = !!c.userList, p), {})
    }, [chatChannels])

    const groupedChannelList = useMemo(() => {
        if (channelList) {
            const channels = cloneDeep(channelList)
            channels.sort((a, b) => (
                Object.keys(typeLabels).indexOf(a.type) - Object.keys(typeLabels).indexOf(b.type)
            ))

            const seenEmailCombinations = new Set();
            const filteredChannelsArray = channels.reduce((accumulator, item) => {
                if (item.type === 'user' && item.users) {
                    const emails = Object.keys(item.users).sort().join(',');
                    if (!seenEmailCombinations.has(emails)) {
                        seenEmailCombinations.add(emails);
                        accumulator.push(item);
                    }
                } else {
                    accumulator.push(item);
                }
                return accumulator;
            }, []);
            const groupedChannels = filteredChannelsArray.reduce((p, c) => (!!p[c.type] ? p[c.type].push(c) : p[c.type] = [c], p), {})
            return groupedChannels
        } else {
            return null
        }
    }, [channelList])

    const chatNotfications = useMemo(() => {
        if (channelList && (unreadChannels.length || newChannels.length)) {
            const channelInfo = channelList.reduce((p, c) => (p[c.ChannelArn] = { name: c.Name, private: c.type === 'user' }, p), {})
            const unread = unreadChannels.filter(arn => !!channelInfo[arn]).map(arn => ({ arn, label: `Unread Messages — ${channelInfo[arn].private ? 'Private Channel' : channelInfo[arn].name}`, description: `You have unread messages in ${channelInfo[arn].private ? 'a private' : `the "${channelInfo[arn].name}"`} channel`, toast: `New chat message in "${channelInfo[arn].name}"` }))
            const newChanx = newChannels.filter(arn => !!channelInfo[arn]).map(arn => ({ arn, label: `New Channel — ${channelInfo[arn].private ? 'Private Channel' : channelInfo[arn].name}`, description: `You have been addded to ${channelInfo[arn].private ? 'a private channel' : `"${channelInfo[arn].name}"`}` }))
            return [...unread, ...newChanx]
        } else {
            return []
        }
    }, [channelList, unreadChannels, newChannels])

    useEffect(() => {
        if (channelList && unreadChannels.length) {
            const channelInfo = channelList.reduce((p, c) => (p[c.ChannelArn] = { name: c.Name, private: c.type === 'user' }, p), {})
            const unread = unreadChannels.filter(arn => !!channelInfo[arn]).map(arn => ({ arn, label: `Unread Messages — ${channelInfo[arn].private ? 'Private Channel' : channelInfo[arn].name}`, description: `You have unread messages in ${channelInfo[arn].private ? 'a private' : `the "${channelInfo[arn].name}"`} channel`, toast: `New chat message in "${channelInfo[arn].name}"` }))
            //console.log("unread ---", unread)
            //console.log("current channel", currentChannel, channelList)
            if (unread.length && currentChannel) {
                const channel = channelList.find(x => x.ChannelArn === unread[0].arn)
                // Get users name from first object
                let userNames;
                if (channel.type === "user") {
                    userNames = Object.values(channel.users);
                }
                // setShowMessageAlert(true)
                enqueueSnackbar(<span onClick={() => handleToastClick(channel.id)}>{channel.type == "user" ? `New chat messages in "${channel.Name} ${userNames[0]}"` : `New chat message in "${channel.Name}"`}</span>,
                    {
                        key: channel.id, preventDuplicate: true, action: DismissBtn, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        }
                    });
                // setAlertMessage({ id: channel.id, message: channel.type == "user" ? `New chat messages in "${channel.Name} ${userNames[0]}"` : `New chat message in "${channel.Name}"` })
            }
        }
    }, [unreadChannels, channelList, currentChannel, enqueueSnackbar, handleToastClick])

    const handleSendMessage = useCallback((editorState) => {
        const rawMessage = convertToRaw(editorState.getCurrentContent())
        const messageContent = draftToMarkdown(rawMessage)
        const attachment = attachments[0]
        const attachmentData = attachment ? new File([attachment], attachment.name, { type: attachment.type }) : null
        setAttachments([])
        if (!!messageContent || !!attachmentData) sendChannelMessage(messageContent, attachmentData)
        return EditorState.createEmpty()
    }, [sendChannelMessage, attachments, setAttachments])

    const updateMessage = useCallback(async (messageId, newMessage, metadata) => {
        updateChannelMessage(messageId, newMessage, metadata)
    }, [updateChannelMessage]);

    const redactMessage = useCallback(async (messageId) => {
        redactChannelMessage(messageId)
    }, [redactChannelMessage]);

    const messageList = useMemo(() => {
        return (channelMessages || []).map((message) => {
            return <ChimeChatMessage
                key={message.MessageId}
                messageId={message.MessageId}
                sender={message.Sender?.Name || "REMOVED"}
                senderId={message.Sender?.Arn}
                content={message.Content}
                editedTime={message.LastEditedTimestamp || null}
                timestamp={message.CreatedTimestamp}
                metadata={message.Metadata}
                redacted={message.Redacted}
                isOutgoing={message.Sender?.Arn === currentUser.arn}
                allowEdit={currentUser.isAdmin}
                saveEdit={updateMessage}
                redact={redactMessage}
                downloadAttachment={downloadMessageAttachment}
            />
        })
    }, [channelMessages, currentUser, updateMessage, redactMessage, downloadMessageAttachment])

    const handleNotificationsPopoverOpen = useCallback((event, filter) => {
        setNotificationsPopoverAnchor(event.currentTarget);
    }, []);

    const handleNotificationsPopoverClose = useCallback((event) => {
        setNotificationsPopoverAnchor(null);
    }, []);

    const handleNotificationClick = useCallback((arn) => {
        setNotificationsPopoverAnchor(null);
        const channel = channelList.find(x => x.ChannelArn === arn)
        setCurrentChannel(channel)
    }, [channelList, setCurrentChannel]);

    const handleNotificationClear = useCallback((arn) => {
        setNotificationsPopoverAnchor(null);
        clearAllNotifications()
    }, [clearAllNotifications]);

    const handleChannelUsersPopoverClose = useCallback((event) => {
        setChannelUsersPopoverAnchor(null);
        setFilterUsersList(null)
        setChannelUsers(null)
        setShowClients(true)
        setShowStaff(true)
    }, []);

    const handleChannelSelect = useCallback((event) => {
        // setCurrentChannel(event.target.value);
        const channel = channelList.find(x => x.id === event.target.value)
        setCurrentChannel(channel)
    }, [channelList, setCurrentChannel]);

    const handleAddAttachment = useCallback(([file]) => {
        setAttachments([file.src.file])
    }, [setAttachments]);

    const handleDeleteAttachment = useCallback((index) => {
        setAttachments(prev => {
            const newAttachments = [...prev]
            newAttachments.splice(index, 1)
            return newAttachments
        })
    }, []);

    const handleSubmitMessage = useCallback((key, event) => {
        event.preventDefault()
        event.target.blur()
        const editorState = handleSendMessage(editorStateRef.current)
        const resetValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        setDefaultValue(resetValue)
        editorRef.current.focus()
    }, [handleSendMessage, setDefaultValue])

    const handleEditorChange = useCallback((editorState) => {
        editorStateRef.current = editorState
    }, [])

    const handleShowChannelGuests = useCallback(async (event) => {
        setChannelUsersPopoverAnchor(event.currentTarget);
        const guests = await getCurrentChannelUsers()
        // Sort Guest by Name
        setFilterUsersList(guests.sort((a, b) => a.Name.localeCompare(b.Name)))
        setChannelUsers(guests)
        setSearchUser("")
    }, [getCurrentChannelUsers])

    const handleManageChannelGuests = useCallback(async () => {
        const guests = await getCurrentChannelUsers()
        onManageChannelGuests(currentChannel, guests)
    }, [currentChannel, getCurrentChannelUsers, onManageChannelGuests])

    const handleSearchUserChange = useCallback(async (event) => {
        setSearchUser(event.target.value)
        //const guests = await getCurrentChannelUsers()
        //console.log(event.target.value, guests)
        const searchUsers = channelUsers.filter(user => user.Name.toLowerCase().includes(event.target.value.toLowerCase()))
        if (event.target.value === "") {
            setFilterUsersList(channelUsers)
        } else {
            console.log("searchUsers", searchUsers);
            setFilterUsersList(searchUsers)
        }
    }, [channelUsers])

    const filterChatOpen = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [])

    const filterChatClose = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const chatUserFilterOptions = useMemo(() => [{
        name: "client",
        label: "Client",
        value: showClients,
        onChange: (e) => {
            setShowClients(e.target.checked);
        }
    }, {
        name: "staff",
        label: "Staff",
        value: showStaff,
        onChange: (e) => {
            setShowStaff(e.target.checked);
        }
    }], [showClients, showStaff])

    useEffect(() => {
        let filteredUsers = []
        if (showClients && showStaff) {
            filteredUsers = channelUsers
        } else if (showClients && !showStaff) {
            filteredUsers = channelUsers.filter(user => emailRegexp.test(user.userId) === false)
        } else if (!showClients && showStaff) {
            filteredUsers = channelUsers.filter(user => emailRegexp.test(user.userId) === true)
        } else {
            filteredUsers = []
        }
        setFilterUsersList(filteredUsers)
    }, [showClients, showStaff, channelUsers])

    return (
        <ThemeProvider theme={lightTheme}>
            <Root theme={theme} highlightArrow={newChannels.length || unreadChannels.length}>
                {!(connected && (started && channelList)) && <div className={classes.loadingCover}>
                    <CircularProgress />
                </div>}
                {/* {
                    showMessageAlert &&
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={showMessageAlert}
                        // autoHideDuration={6000}
                        onClose={() => setShowMessageAlert(false)}
                    >
                        <Alert
                            onClose={() => setShowMessageAlert(false)}
                            severity='info'
                            // color='warning'
                            variant='filled'
                        // classes={{
                        //     root: classes.toastBackgroundColor,
                        //     icon: classes.toastIconColor,
                        //     message: classes.toastTextColor,
                        //     action: classes.toastTextColor
                        // }}
                        >
                            <span onClick={() => handleToastClick(alertMessage.id)}>{alertMessage.message}</span>
                        </Alert>
                    </Snackbar>
                } */}
                {(started && groupedChannelList) && <>
                    <AppBar position="static">
                        <Toolbar className={classes.header}>
                            <IconButton
                                color="inherit"
                                onClick={handleNotificationsPopoverOpen}
                            >
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    // anchorOrigin={{
                                    //     vertical: "top",
                                    //     horizontal: "left"
                                    // }}
                                    // classes={{
                                    //     badge: classes.unreadBadge
                                    // }}
                                    invisible={!chatNotfications.length}
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            {(!(channelList?.length > 1)) ?
                                <Typography variant="h5" className={classes.channelText}>{currentChannel?.type !== "user" ? currentChannel?.Name : `${currentChannel?.Name} ${currentChannel?.users ? Object.entries(currentChannel.users).filter(([k, v]) => k !== `${currentUser.userId}`).map(([k, v]) => v).join(', ') : ""}`}</Typography>
                                :
                                <Select
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    value={currentChannel?.id || 'no-channel'}
                                    onChange={handleChannelSelect}
                                    className={classes.selector}
                                    classes={{
                                        root: classes.channelSelector,
                                        select: classes.channelSelectorText,
                                        icon: classes.channelSelectorIcon
                                    }}
                                >
                                    <MenuItem key='no-channel' value='no-channel' style={{ display: 'none' }}>NO CHANNEL SELECTED</MenuItem>
                                    {Object.entries(groupedChannelList).map(([group, channels]) => [
                                        <ListSubheader key={`channelgroup-${group}`}>{typeLabels[group]}</ListSubheader>,
                                        channels.map((channel) => (
                                            <MenuItem key={channel.ChannelArn} value={channel.id}>
                                                <Badge
                                                    color="secondary"
                                                    variant="dot"
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    }}
                                                    classes={{
                                                        badge: classes.unreadBadge
                                                    }}
                                                    invisible={!unreadChannels.includes(channel.ChannelArn) || (currentUser.isAdmin && channel.restricted)}
                                                >
                                                    <div className={classes.channelItem}>
                                                        {/* <ListItemIcon>
                                                    {unreadChannels.includes(channel.ChannelArn) ? <NewReleasesIcon /> : <RemoveOutlinedIcon />}
                                                </ListItemIcon> */}
                                                        {/* {unreadChannels.includes(channel.ChannelArn) && <ListItemIcon >
                                                    <NewReleasesIcon />
                                                </ListItemIcon>} */}
                                                        {/* <ListItemText primary={channel.Name} /> */}
                                                        {channel.type !== "user" ? channel.Name : `${channel.Name} ${channel.users ? Object.entries(channel.users).filter(([k, v]) => k !== `${currentUser.userId}`).map(([k, v]) => v).join(', ') : ""}`}
                                                        {/* {!newChannels.includes(channel.ChannelArn) && <ListItemIcon>
                                                            <FiberNewOutlinedIcon />
                                                        </ListItemIcon>} */}
                                                        {(currentUser.isAdmin && channel.restricted) && <LockIcon className={classes.channelSubIcon} />}
                                                        {newChannels.includes(channel.ChannelArn) && <FiberNewOutlinedIcon className={classes.channelSubIcon} color="secondary" />}
                                                    </div>
                                                </Badge>
                                            </MenuItem>
                                        ))
                                    ])}
                                </Select>
                            }
                            {(currentChannel?.type !== 'user' && (currentUser?.isAdmin || !!(currentChannel?.id && showUsers[currentChannel.id]))) ?
                                (currentUser?.isAdmin && currentChannel?.type === 'private' && onManageChannelGuests)
                                    ?
                                    <IconButton
                                        color="inherit"
                                        onClick={handleManageChannelGuests}
                                    >
                                        <GroupAddIcon />
                                    </IconButton>
                                    :
                                    <IconButton
                                        color="inherit"
                                        onClick={handleShowChannelGuests}
                                    >
                                        <GroupIcon />
                                    </IconButton>
                                :
                                <div className={classes.iconSpacer}>&nbsp;</div>}
                        </Toolbar>
                    </AppBar>
                    <Popover
                        open={!!channelUsersPopoverAnchor}
                        anchorEl={channelUsersPopoverAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handleChannelUsersPopoverClose}
                        disableRestoreFocus
                    >
                        <List className={classes.popoverList}>
                            <TextField
                                id="channel-users-search"
                                label="Search"
                                type="search"
                                variant="outlined"
                                size="small"
                                value={searchUser}
                                style={{ marginLeft: "5px", marginRight: "5px" }}
                                onChange={handleSearchUserChange}
                            />
                            <IconButton onClick={filterChatOpen}>
                                <FilterListIcon />
                            </IconButton>
                            {(filterUsersList && filterUsersList.length) ? filterUsersList.map((user, ui, uax) => {
                                // const subLabel = emailRegexp.test(user.userId) ? user.userId : `VSR ID #${user.userId}`
                                const isStaff = emailRegexp.test(user.userId)
                                const subLabel = isStaff ? 'VSR STAFF' : `VSR ID #${user.userId}`
                                const showStaff = !isStaff || !currentChannel?.restricted
                                return showStaff && (
                                    <React.Fragment key={`chatuser${user.userId}-${ui}`}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={user.Name}
                                                secondary={subLabel}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" disabled={user.userId === currentUser.userId} onClick={() => openPrivateChannel(user.userId, user.Name)}>
                                                    <ChatIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {(ui !== uax.length - 1) && <Divider component="li" />}
                                    </React.Fragment>
                                )
                            }).filter(x => x)
                                :
                                <ListItem alignItems="center" style={{ justifyContent: 'center' }}>
                                    <div>
                                        <CircularProgress size="2em" />
                                    </div>
                                </ListItem>
                            }
                        </List>
                    </Popover>
                    <Popover
                        open={!!notificationsPopoverAnchor}
                        anchorEl={notificationsPopoverAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handleNotificationsPopoverClose}
                        disableRestoreFocus
                    >
                        <List className={classes.popoverList}>
                            {(chatNotfications.length) ? chatNotfications.map((notification, ni, nax) => (
                                <React.Fragment key={`notification${notification.arn}-${ni}`}>
                                    <ListItem alignItems="flex-start" className={classes.notificationBox} onClick={() => handleNotificationClick(notification.arn)}>
                                        <ListItemText
                                            primary={notification.label}
                                            secondary={notification.description}
                                        />
                                    </ListItem>
                                    {(ni !== nax.length - 1) ?
                                        <Divider component="li" />
                                        :
                                        <ListItem alignItems="center" style={{ justifyContent: 'flex-end' }}>
                                            <div>
                                                <Button color="grey" startIcon={<ClearAllIcon />} onClick={handleNotificationClear}>
                                                    Clear All
                                                </Button>
                                            </div>
                                        </ListItem>}
                                </React.Fragment>
                            ))
                                :
                                <ListItem alignItems="center">
                                    <ListItemText
                                        style={{ textAlign: 'center' }}
                                        primary="No Notifications"
                                    />
                                </ListItem>
                            }
                        </List>
                    </Popover>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={filterChatClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    >
                        <FilterRoot>
                            <ul className={classes.sessionFilterOptions}>
                                {chatUserFilterOptions.map(option => <li key={option.name}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={option.value}
                                                onChange={option.onChange}
                                                name={option.name}
                                                color="primary"
                                            ></Checkbox>
                                        }
                                        label={option.label}
                                    ></FormControlLabel>
                                </li>)}
                            </ul>
                        </FilterRoot>
                    </Popover>
                    {attachingFile && <LinearProgress className={classes.attachLoading} color="secondary" />}
                    {(currentChannel && Object.keys(groupedChannelList).length) ?
                        !channelMessages ?
                            <div className={classes.nochannel}>
                                {(currentUser.isAdmin && currentChannel.restricted) ? <>
                                    <LockIcon />
                                    <Typography>Channel Messages are Restricted to Users</Typography>
                                </> : <CircularProgress />}
                            </div> : <InfiniteList
                                // className={classes.messages}
                                items={messageList}
                                onLoad={loadChannelMessages}
                                css="flex: 1; width: 100%; margin: 0; overflow-y: auto; overflow-x: hidden"
                                isLoading={loadingMessages}
                            /> : <div className={classes.nochannel}>
                            <Typography>
                                {Object.keys(groupedChannelList).length ? 'No Channel Selected' : 'No Chat Channels Available'}
                            </Typography>
                        </div>}
                    {(currentChannel && !(currentUser.isAdmin && currentChannel.restricted)) && <MessageRoot>
                        <KeyboardEventHandler
                            // isExclusive={true}
                            className={classes.inputText}
                            handleKeys={["enter"]}
                            onKeyEvent={handleSubmitMessage}>
                            <Files
                                multiple={false} maxSize="25mb" //accept={['*']}
                                onSuccess={handleAddAttachment}
                            // onError={onError}
                            >
                                {({ browseFiles }) => (
                                    <MUIRichTextEditor
                                        disabled={true}
                                        ref={editorRef}
                                        label={'\u00A0'}
                                        className={classes.chatInput}
                                        onChange={handleEditorChange}
                                        defaultValue={defaultValue}
                                        toolbarButtonSize="small"
                                        inlineToolbar={true}
                                        controls={["bold", "italic", "strikethrough", "clear", "link", "numberList", "bulletList", "quote", "code", "add-attachment", "send"]}
                                        inlineToolbarControls={["bold", "italic", "strikethrough", "link", "clear"]}
                                        customControls={[
                                            {
                                                name: "add-attachment",
                                                icon: <AttachFileIcon />,
                                                type: "callback",
                                                onClick: browseFiles
                                            },
                                            {
                                                name: "send",
                                                icon: <SendIcon />,
                                                type: "callback",
                                                onClick: handleSendMessage
                                            }
                                        ]}
                                    // keyCommands={[
                                    //     {
                                    //         key: 13,
                                    //         name: 'enter',
                                    //         callback: handleSendMessage
                                    //     }
                                    // ]}
                                    // autocomplete={{
                                    //     strategies: [
                                    //         {
                                    //             items: emojiAutocomplete,
                                    //             triggerChar: ":"
                                    //         }
                                    //     ]
                                    // }}
                                    />
                                )}
                            </Files>
                        </KeyboardEventHandler>
                    </MessageRoot>}
                    <ul className={classes.chipList}>
                        {attachments.map((attachment, i) => (
                            <li key={`chat-attachment-chip${i}`}>
                                <Chip
                                    label={attachment.name}
                                    onDelete={() => handleDeleteAttachment(i)}
                                    size="small"
                                    className={classes.chip}
                                />
                            </li>
                        ))}
                    </ul>
                </>}
            </Root>
        </ThemeProvider>
    );

})

const ChimeChatMessage = React.memo(({ messageId, sender, senderId, content, editedTime, timestamp, metadata, redacted, isOutgoing, allowEdit, saveEdit, redact, downloadAttachment }) => {

    const [editing, setEditing] = useState(false)

    const metadataObj = useMemo(() => {
        try {
            return metadata && JSON.parse(metadata)
        } catch (er) {
            return null
        }
    }, [metadata])

    const chatActions = useMemo(() => {
        return (isOutgoing && allowEdit) ? [
            <PopOverItem
                key="edit-chat-message"
                children={<span>Edit</span>}
                onClick={() => setEditing(true)}
            />,
            <PopOverItem
                key="delete-chat-message"
                children={<span>Delete</span>}
                onClick={() => redact(messageId)}
            />,
        ] : null
    }, [isOutgoing, allowEdit, messageId, redact])

    const editNote = useMemo(() => {
        if (editedTime) {
            const time = formatTime(editedTime);
            const date = formatDate(editedTime, undefined, undefined, 'today', 'yesterday');
            return ` Edited ${date} at ${time}`
        } else {
            return null
        }
    }, [editedTime])

    const handleSave = useCallback((event, newValue) => {
        saveEdit(messageId, newValue, metadata)
        setEditing(false)
    }, [saveEdit, messageId, metadata])

    const cancelEdit = useCallback(() => {
        setEditing(false)
    }, [])

    return !redacted ? (
        <div>
            <ChatBubbleContainer
                timestamp={formatTime(timestamp)}
                actions={chatActions}
                css="padding: 1rem; box-sizing: border-box"
            >
                {(editing && !redacted) ? (
                    <EditableChatBubble
                        variant={isOutgoing ? 'outgoing' : 'incoming'}
                        senderName={sender}
                        content={content}
                        save={handleSave}
                        cancel={cancelEdit}
                        showName={true}
                    // showTail={showTail}
                    />
                ) : (
                    <ChatBubble
                        variant={isOutgoing ? 'outgoing' : 'incoming'}
                        senderName={sender}
                        redacted={redacted}
                        showName={true}
                    // showTail={showTail}
                    >
                        <div className="chat-message">
                            <ReactMarkdown remarkPlugins={[[emoji, { emoticon: true, padSpaceAfter: true }], [gfm, { singleTilde: false }], [remarkExternalLinks, { rel: false }]]} children={content} />
                            {!!editNote && <div className="chat-edit-note">
                                {editNote}
                            </div>}
                        </div>
                        {(metadataObj && metadataObj.attachments) && (
                            <div style={{ width: 'fit-content', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={() => downloadAttachment(metadataObj.attachments[0])}>
                                <MessageAttachment
                                    name={metadataObj.attachments[0].name}
                                    // downloadUrl={getS3FileUrl(attachmentLocation, senderId, metadataObj.attachments[0].fileKey)}
                                    size={formatBytes(metadataObj.attachments[0].size)}
                                />
                                {/* <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <CircularProgress size={'2rem'} />
                                    </div> */}
                            </div>
                        )}
                    </ChatBubble>
                )}
            </ChatBubbleContainer>
        </div>
    ) : null;
})

export default (ChimeChat)