import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, Modal, Paper, Typography, Button, Tabs, Tab, Collapse, Fade, Divider } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import StandardField from './fields/StandardField';
import { clearLocalStorage, useLocalStorage } from '../../hooks/hooks'
const PREFIX = 'StandardModal';

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  body: `${PREFIX}-body`,
  headerText: `${PREFIX}-headerText`,
  footer: `${PREFIX}-footer`,
  button: `${PREFIX}-button`,
  divider: `${PREFIX}-divider`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Modal)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '30px',
  paddingBottom: '30px',
  width: '100vw',
  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    width: '80%',
    minWidth: '300px',
    padding: '15px 0 0 0',
    maxHeight: '98%'
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 0 15px',
    marginBottom: '6px'
  },
  [`& .${classes.body}`]: {
    padding: '0 15px 0 15px',
    overflowY: 'auto'
  },
  [`& .${classes.headerText}`]: {
    margin: 'auto 0 auto 0'
  },
  [`& .${classes.footer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 0 15px 0',
    marginTop: '6px'
  },
  [`& .${classes.button}`]: {
    margin: '3px'
  },
  [`& .${classes.divider}`]: {
    margin: '1rem'
  }
});

// import { DropzoneArea } from 'material-ui-dropzone'

const cloneDeep = require('lodash/cloneDeep');

const StandardModal = React.memo(({ modalKey, data, handleClose, onSubmit, open, title, titleKey, tabs = [], fields = [], buttonLabel, children, ...restProps }) => {
  const [params, setParams] = useLocalStorage(`${modalKey}-params`, data || {}) //useState(data || {});
  const [currentTab, setCurrentTab] = useState(0) //useLocalStorage(`${modalKey}-tab`, 0)
  const [invalidFields, setInvalidFields] = useState(new Set());


  const tabsActions = useRef();
  const paramsRef = useRef(params);

  useEffect(() => {
    return () => {
      clearLocalStorage([`${modalKey}-params`, `${modalKey}-tab`])
    }
  })

  useEffect(() => {
    const newParams = {}
    const invalid = new Set()
    fields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((fld) => {
          newParams[fld.key] = data ? data[fld.key] : fld.defaultValue !== undefined ? fld.defaultValue : null
          if (fld.required && (!newParams[fld.key] || newParams[fld.key].length === 0)) invalid.add(fld.key)
        })
      } else {
        newParams[field.key] = data ? data[field.key] : field.defaultValue !== undefined ? field.defaultValue : null
        if (field.required && (!newParams[field.key] || newParams[field.key].length === 0)) invalid.add(field.key)
      }
    })
    tabs.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (Array.isArray(field)) {
          field.forEach((fld) => {
            const [f, k, z] = fld.key.split('.')
            if (k) {
              if (!newParams[f]) newParams[f] = {}
              if (z) {
                if (!newParams[f][k]) newParams[f][k] = {}
                newParams[f][k][z] = (data && data[f] && data[f][k]) ? data[f][k][z] : fld.defaultValue !== undefined ? fld.defaultValue : null
                if (fld.required && (!newParams[f][k][z] || newParams[f][k][z].length === 0)) invalid.add(fld.key)
              } else {
                newParams[f][k] = (data && data[f]) ? data[f][k] : fld.defaultValue !== undefined ? fld.defaultValue : null
                if (fld.required && (!newParams[f][k] || newParams[f][k].length === 0)) invalid.add(fld.key)
              }
            } else {
              newParams[fld.key] = data ? data[fld.key] : fld.defaultValue !== undefined ? fld.defaultValue : null
              if (fld.required && (!newParams[fld.key] || newParams[fld.key].length === 0)) invalid.add(fld.key)
            }
          })
        } else {
          const [f, k, z] = field.key.split('.')
          if (k) {
            if (!newParams[f]) newParams[f] = {}
            if (z) {
              if (!newParams[f][k]) newParams[f][k] = {}
              newParams[f][k][z] = (data && data[f] && data[f][k]) ? data[f][k][z] : field.defaultValue !== undefined ? field.defaultValue : null
              if (field.required && (!newParams[f][k][z] || newParams[f][k][z].length === 0)) invalid.add(field.key)
            } else {
              newParams[f][k] = (data && data[f]) ? data[f][k] : field.defaultValue !== undefined ? field.defaultValue : null
              if (field.required && (!newParams[f][k] || newParams[f][k].length === 0)) invalid.add(field.key)
            }
          } else {
            newParams[field.key] = data ? data[field.key] : field.defaultValue !== undefined ? field.defaultValue : null
            if (field.required && (!newParams[field.key] || newParams[field.key].length === 0)) invalid.add(field.key)
          }
        }
      })
    })
    // console.log('setting params & initial invalid')
    setInvalidFields(prev => new Set([...prev], [...invalid]))
    if (!data && !Object.keys(paramsRef.current).length) setParams(newParams)
  }, [data, fields, tabs, setParams])

  useEffect(() => {
    paramsRef.current = params;
    // console.log('params change', params)
  }, [params])

  const handleValueChange = useCallback((event, newValue, field, changeEffects, valueKey) => {
    // const data = cloneDeep(paramsRef.current)
    // const newParams = { ...data, [field]: (newValue && valueKey) ? newValue[valueKey] : newValue }
    const newParams = cloneDeep(paramsRef.current)
    const [f, k, z] = field.split('.')
    const changeValue = (newValue && valueKey) ? Array.isArray(newValue) ? newValue.map(x => x[valueKey]) : newValue[valueKey] : newValue
    // if (k) {
    //   if (newParams[f]) {
    //     newParams[f][k] = changeValue
    //   } else {
    //     newParams[f] = {
    //       [k]: changeValue
    //     }
    //   }
    // } 
    if (k) {
      if (!newParams[f]) newParams[f] = {}
      if (z) {
        if (!newParams[f][k]) newParams[f][k] = {}
        newParams[f][k][z] = changeValue
      } else {
        newParams[f][k] = changeValue
      }
    } else {
      newParams[field] = changeValue
    }
    // if (changeEffects) {
    //   changeEffects.forEach((change) => {
    //     newParams[change.fieldKey] = typeof change.value === 'function' ? change.value(newValue) : change.value
    //   })
    // }
    if (changeEffects && typeof changeEffects === 'function') {
      Promise.resolve(changeEffects(newParams, newValue)).then(() => {
        setParams(newParams)
      })
    } else {
      setParams(newParams)
    }
    // console.log(data, newParams)
  }, [setParams])

  const handleCustomButtonAction = useCallback((buttonAction) => {
    if (buttonAction && typeof buttonAction === 'function') {
      const newParams = cloneDeep(paramsRef.current)
      Promise.resolve(buttonAction(newParams)).then(() => {
        setParams(newParams)
      })
    }
  }, [setParams])

  const handleTabChange = useCallback((e, newValue) => {
    e.currentTarget.blur()
    setCurrentTab(newValue);
  }, [])

  const handleSubmit = useCallback(e => {
    e.currentTarget.blur()
    onSubmit(e, paramsRef.current)
  }, [onSubmit]);

  const setInvalid = useCallback((field, invalid) => {
    setInvalidFields(prev => {
      const newInvalid = new Set(prev)
      invalid ? newInvalid.add(field) : newInvalid.delete(field)
      return newInvalid
    })
  }, []);

  const isInvalid = useMemo(() => {
    // invalidFields.forEach(x => console.log('invalid field:', x))
    return !!invalidFields.size
  }, [invalidFields.size])

  return (
    (<Root
      className={classes.modal}
      open={open}
      // closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
          sx: { zIndex: -1 }
        }
      }}
    >
      <Fade in={open}>
        {/* <Grow in={open} onEnter={resizeTabs}> */}
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h5" className={classes.headerText}>{title instanceof Function ? title(params) : title}</Typography>
            {tabs && tabs.length > 0 &&
              <Tabs
                action={tabsActions}
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="search filter tab"
                variant="scrollable"
                scrollButtons="off"
              >
                {tabs.map((tab) =>
                  <Tab key={tab.key} label={tab.label} disabled={tab.disabled instanceof Function ? tab.disabled(params) : tab.disabled} />
                )}
              </Tabs>}
          </div>
          <div className={classes.body}>
            <form autoCapitalize="off">
              {tabs && tabs.length > 0 &&
                tabs.map((tab, tabIndex) =>
                  tabIndex === currentTab && tab.fields && tab.fields.map((field, fi) => {
                    const [fl, kl, zl] = Array.isArray(field) ? [] : field.key.split('.')
                    return Array.isArray(field) ?
                      <div key={`field${fi}${field.key}`}>
                        {field.map((fld, i) => {
                          const [f, k, z] = fld.key.split('.')
                          return (
                            <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${i}${fld.key}`} in={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={classes.field}>
                              <StandardField
                                value={k ? params[f] && (z ? params[f][k] && params[f][k][z] : params[f][k]) : params[fld.key]}
                                fieldKey={fld.key}
                                invalid={fld.subkey ? invalidFields.has(`${fld.key}-${fld.subkey}`) : invalidFields.has(fld.key)}
                                invalidate={setInvalid}
                                conditional={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                                conditionMet={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                                onChange={(e, v, k) => handleValueChange(e, v, fld.key, fld.onUpdate, k)}
                                onButtonClick={fld.buttonAction && (() => handleCustomButtonAction(fld.buttonAction))}
                                {...fld}
                                disabled={fld.disabled instanceof Function ? fld.disabled(params) : fld.disabled}
                                // options={typeof fld.options === 'function' ? fld.options(params) : fld.options}
                                options={fld.options}
                                disabledOptions={fld.disabledOptions instanceof Function ? (opt) => fld.disabledOptions(params, opt) : undefined} />
                            </Collapse>
                          )
                        })}
                        <Divider className={classes.divider} />
                      </div>
                      :
                      <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${fi}${field.key}`} in={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={classes.field}>
                        <StandardField
                          value={kl ? params[fl] && (zl ? params[fl][kl] && params[fl][kl][zl] : params[fl][kl]) : params[field.key]}
                          fieldKey={field.key}
                          invalid={field.subkey ? invalidFields.has(`${field.key}-${field.subkey}`) : invalidFields.has(field.key)}
                          invalidate={setInvalid}
                          conditional={(field.conditions && field.conditions.length) ? field.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                          conditionMet={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                          onChange={(e, v, k) => handleValueChange(e, v, field.key, field.onUpdate, k)}
                          onButtonClick={field.buttonAction && (() => handleCustomButtonAction(field.buttonAction))}
                          {...field}
                          disabled={field.disabled instanceof Function ? field.disabled(params) : field.disabled}
                          // options={typeof field.options === 'function' ? field.options(params) : field.options} 
                          options={field.options}
                          disabledOptions={field.disabledOptions instanceof Function ? (opt) => field.disabledOptions(params, opt) : undefined} />
                      </Collapse>
                  })
                )
              }
              {fields && fields.length > 0 &&
                fields.map((field, fi) => {
                  const [fl, kl, zl] = Array.isArray(field) ? [] : field.key.split('.')
                  return Array.isArray(field) ?
                    <div key={`field${fi}${field.key}`}>
                      {field.map((fld, i) => {
                        const [f, k, z] = fld.key.split('.')
                        return (
                          <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${i}${fld.key}`} in={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={classes.field}>
                            <StandardField
                              value={k ? params[f] && (z ? params[f][k] && params[f][k][z] : params[f][k]) : params[fld.key]}
                              fieldKey={fld.key}
                              invalid={fld.subkey ? invalidFields.has(`${fld.key}-${fld.subkey}`) : invalidFields.has(fld.key)}
                              invalidate={setInvalid}
                              conditional={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                              conditionMet={(fld.conditions && fld.conditions.length) ? fld.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                              onChange={(e, v, k) => handleValueChange(e, v, fld.key, fld.onUpdate, k)}
                              onButtonClick={fld.buttonAction && (() => handleCustomButtonAction(fld.buttonAction))}
                              {...fld}
                              disabled={fld.disabled instanceof Function ? fld.disabled(params) : fld.disabled}
                              // options={typeof fld.options === 'function' ? fld.options(params) : fld.options}
                              options={fld.options}
                              disabledOptions={fld.disabledOptions instanceof Function ? (opt) => fld.disabledOptions(params, opt) : undefined} />
                          </Collapse>
                        )
                      })}
                      <Divider className={classes.divider} />
                    </div>
                    :
                    <Collapse mountOnEnter={false} unmountOnExit={true} key={`collapse${fi}${field.key}`} in={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true} className={classes.field}>
                      <StandardField
                        value={kl ? params[fl] && (zl ? params[fl][kl] && params[fl][kl][zl] : params[fl][kl]) : params[field.key]}
                        fieldKey={field.key}
                        invalid={field.subkey ? invalidFields.has(`${field.key}-${field.subkey}`) : invalidFields.has(field.key)}
                        invalidate={setInvalid}
                        conditional={(field.conditions && field.conditions.length) ? field.conditions.map(condition => !!condition.required).reduce((p, c) => p && c) : false}
                        conditionMet={(field.conditions && field.conditions.length) ? field.conditions.map(condition => condition.condition(params)).reduce((p, c) => p && c) : true}
                        onChange={(e, v, k) => handleValueChange(e, v, field.key, field.onUpdate, k)}
                        onButtonClick={field.buttonAction && (() => handleCustomButtonAction(field.buttonAction))}
                        {...field}
                        disabled={field.disabled instanceof Function ? field.disabled(params) : field.disabled}
                        // options={typeof field.options === 'function' ? field.options(params) : field.options}
                        options={field.options}
                        disabledOptions={field.disabledOptions instanceof Function ? (opt) => field.disabledOptions(params, opt) : undefined} />
                    </Collapse>
                })
              }
              <div className={classes.footer}>
                <Button className={classes.button} variant="contained" onClick={handleSubmit} disabled={isInvalid} color="primary">{buttonLabel}</Button>
                <Button className={classes.button} variant="contained" onClick={handleClose}>Cancel</Button>
              </div>
            </form>
          </div>
        </Paper>
        {/* </Grow> */}
      </Fade>
    </Root>)
  );
})

StandardModal.defaultProps = {
  title: 'Modal',
  buttonLabel: 'Submit',
  tabs: [],
  fields: []
}

export default StandardModal;
