import React, { useEffect, useRef, useCallback } from 'react';
import './FreshdeskWidget.css'

const widgetObserver = new MutationObserver((mutations) => {
    const last = mutations[mutations.length - 1]
    if (last.addedNodes.length) {
        last.target.parentElement.classList.add('fw-container')
    } else if (last.removedNodes.length) {
        last.target.parentElement.classList.remove('fw-container')
    }
})

const childObserver = new MutationObserver((mutations) => {
    const child = mutations.find((mutation) => mutation.addedNodes && mutation.target.id === 'freshworks-container')
    if (child.addedNodes[0]) {
        widgetObserver.observe(child.addedNodes[0], { childList: true })
        childObserver.disconnect()
    }
})

const bodyObserver = new MutationObserver((mutations) => {
    const widget = mutations.find((mutation) => mutation.addedNodes && Array.from(mutation.addedNodes).find(node => node.id === 'freshworks-container'))
    if (widget?.addedNodes[0]) {
        childObserver.observe(widget.addedNodes[0], { childList: true })
        bodyObserver.disconnect()
    }
})

const FreshdeskWidget = React.memo(({ widgetId, handleOpenRef, name, email }) => {

    const nodeRef = useRef()

    useEffect(() => {
        window.fwSettings = {
            'widget_id': widgetId
        }

        const script = document.createElement('script');
        script.type = "text/javascript"
        script.src = "https://widget.freshworks.com/widgetBase/bootstrap.js"
        script.async = true
        script.defer = true
        script.onload = () => {
            (function () {
                if (typeof window.FreshworksWidget !== "function") {
                    var n = function () {
                        n.q.push(arguments)
                    };
                    n.q = []
                    window.FreshworksWidget = n
                }
            })()
            window.FreshworksWidget('hide', 'launcher')
            bodyObserver.observe(document.body, { childList: true })
            window.FreshworksWidget('setLabels', {
                en: {
                    banner: 'VirtuWorks SMC Support',
                    launcher: '',
                    contact_form: {
                        title: 'Complete your support request below:',
                        submit: 'Submit',
                        confirmation: 'Thank you. We will contact you soon.'
                    }
                }
            })
            console.log(name, email)
            window.FreshworksWidget('identify', 'ticketForm', { name, email })
            window.FreshworksWidget('prefill', 'ticketForm', {
                custom_fields: {
                    cf_affected_system: 'VSR',
                    // cf_team: ''
                }
            });
            window.FreshworksWidget('disable', 'ticketForm', ['custom_fields.cf_affected_system']);
        }
        console.log('ADDING FRESHDESK WIDGET')
        nodeRef.current = document.body.appendChild(script);

        return () => {
            console.log('REMOVING FRESHDESK WIDGET')
            document.body.removeChild(nodeRef.current);
            nodeRef.current = null
        }
    }, [widgetId, name, email])

    const openHandler = useCallback(() => {
        window.FreshworksWidget('open')
    }, [])
    if (handleOpenRef) handleOpenRef.current = openHandler

    return (<></>);
})

export default FreshdeskWidget;
