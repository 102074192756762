import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentUser } from './recoil/atoms'
import queryString from 'query-string';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { getSessionDataPromise } from './services/sessionsService'
import AppNavBar from './components/AppNavBar';
import LoadingPage from './pages/LoadingPage'
import { ErrorPage } from './pages/ErrorPage'
import SessionDialPage from './SessionDialPage'
import config from './config.json'
const PREFIX = 'ClientApp';

const classes = {
  body: `${PREFIX}-body`,
};

const Root = styled('div')({
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  // overflow: 'hidden',
  [`& .${classes.body}`]: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
});

const overrides = {
  MUIRichTextEditor: {
    root: {
      width: "100%",
      border: "1px solid #bdbdbd",
      borderRadius: '4px',
      boxSizing: 'border-box'
    },
    editorContainer: {
      padding: '0.6rem',
      margin: 0,
      boxSizing: 'border-box'
    },
    placeHolder: {
      position: 'relative'
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '0.5rem',
      paddingBottom: '0.5rem',
      borderBottom: '1px solid #e0e0e0'
    }
  }
}

const themes = {
  seasi: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#454f8c'
      },
      secondary: {
        main: '#B80C09'
      },
      segments: {
        all: '#BA274A',//'#92374D',
        default: '#17768D',
        unselected: '#888888',
        Male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
        Female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
        Other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
      },
      dial: '#7C9EB2', //677BA8
      selectedDial: '#17768D',//'#008bf9',
      tuneIn: '#3BB273',//'#58BC82',
      tuneOut: '#E15554',//'#DB504A'
    },
    overrides
  })),
  pfm: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#6C54A4'
      },
      secondary: {
        main: '#EE6352'
      },
      // all: '#BA274A',//'#92374D',
      // male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      // female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      // other: '#E1BC29',
      // genders: {
      //     male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      //     female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      //     other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
      // },
      // respondents: '#17768D',
      // dial: '#7C9EB2', //677BA8
      // selectedDial: '#17768D',//'#008bf9',
      // tuneIn: '#3BB273',//'#58BC82',
      // tuneOut: '#E15554',//'#DB504A'
    },
    overrides
  })),
  vvtv: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#be8b1f'
      },
      secondary: {
        main: '#B91372'//'#D34F73'
      },
    },
    overrides
  })),
  ew: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#0099ff'
      },
      secondary: {
        main: '#D64045' //'#E26D5C'
      },
    },
    overrides
  })),
  cpt: responsiveFontSizes(createTheme({
    palette: {
      // primary: {
      //   main: '#6C54A4'
      // }
    },
    overrides
  })),
};

const ClientApp = React.memo(({ ...props }) => {
  const location = useLocation();
  const { sessionId, projectId } = useParams();
  const { search, state } = location;
  console.log('render CLIENT app', props)
  const [theme, setCurrentTheme] = useState('seasi')
  const [loaded, setLoaded] = useState({})
  const [user, setCurrentUser] = useRecoilState(currentUser)

  const { id } = queryString.parse(search);

  useEffect(() => {
    if (state?.user?.token) {
      setCurrentUser(state.user)
    }
    setLoaded({ user: true })
  }, [state])

  useEffect(() => {
    if (loaded.user) {
      if (!user?.token) {
        getSessionDataPromise(sessionId, id).then((session) => {
          setCurrentTheme(Object.keys(themes).includes(session.team) ? session.team : 'seasi')
          const client = {
            firstName: session.firstName,
            lastName: session.lastName,
            chimeUsername: session.chimeUsername,
            email: session.email,
            role: session.role,
            hasChat: session.hasChat,
            token: id,
            zoomRegistrantId: session.zoomRegistrantId
          }
          setCurrentUser(client)
          setLoaded({ isLoaded: true, sessionId: session.sessionId })
        }).catch((e) => {
          console.log(e)
          const error = (typeof e.error !== 'object') ? e.error : 'Link Expired/Unauthorized'
          setLoaded({ isLoaded: true, error })
        })
      } else {
        setLoaded({ isLoaded: true, sessionId: state?.sessionId })
      }
    }
  }, [loaded, user, id, sessionId, state, setCurrentUser])

  return (loaded.isLoaded) ?
    (loaded.error ? <div style={{
      height: '100%',
      width: '100%',
      minHeight: '100vh',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column'
    }}><ErrorPage error={loaded.error} message="Please contact the site administrator" /></div>
      : <ThemeProvider theme={themes[theme]}>
        <Root>
          <AppNavBar />
          <SessionDialPage sessionId={loaded?.sessionId} projectId={loaded?.projectId} client={true} />
        </Root>
      </ThemeProvider>)
    : <LoadingPage />;
})

export default ClientApp;
