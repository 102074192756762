import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import useWebSocket from 'react-use-websocket';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/material/styles';
import { Button, CircularProgress, Checkbox, Typography } from '@mui/material';
import { useLocalStorage, clearLocalStorage } from '../hooks/hooks';
import clsx from 'clsx';
import StandardField from '../components/standard/fields/StandardField';
import LoadingPage from './LoadingPage'
import ReactCodeInput from 'react-code-input';
import seasiLogo from '../resources/seasi-logo.jpg'
import config from '../config.json'

const PREFIX = 'MagicLogin';

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  formWrap: `${PREFIX}-formWrap`,
  inputWrap: `${PREFIX}-inputWrap`,
  error: `${PREFIX}-error`,
  input: `${PREFIX}-input`,
  spacer: `${PREFIX}-spacer`,
  formLabel: `${PREFIX}-formLabel`,
  btn: `${PREFIX}-btn`,
  blue: `${PREFIX}-blue`,
  grey: `${PREFIX}-grey`,
  disabled: `${PREFIX}-disabled`,
  buttonRow: `${PREFIX}-buttonRow`,
  buttonBox: `${PREFIX}-buttonBox`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  logoWrap: `${PREFIX}-logoWrap`,
  logo: `${PREFIX}-logo`,
  heading: `${PREFIX}-heading`,
  help: `${PREFIX}-help`,
  checkGroup: `${PREFIX}-checkGroup`,
  checkbox: `${PREFIX}-checkbox`
};

const Root = styled('div')({
  display: "flex",
  flexDirection: "row",
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  [`& .${classes.main}`]: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.4",
    backgroundColor: "#fff",
    color: "#777",
    position: "relative",
    overflow: "auto",
    borderRadius: "3px",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "auto",
    margin: "10px auto 8px",
    width: "400px",
    minWidth: "300px",
    borderColor: "#ddd #ddd #d8d8d8",
    boxShadow: "0 2px 0 hsla(0,0%,68.6%,.12)",
  },
  [`& .${classes.formWrap}`]: {
    padding: "37px 42px 32px"
  },
  [`& .${classes.inputWrap}`]: {
    borderRadius: "3px",
    width: "100%",
    boxSizing: "border-box",
    height: "40px",
    border: "1px solid #bbb",
    display: "inline-block",
    backgroundColor: "#fff",
    borderColor: "#bbb",
    position: "relative",
  },
  [`& .${classes.error}`]: {
    borderColor: "#f00",
  },
  [`& .${classes.input}`]: {
    height: "100%",
    width: "100%",
    margin: "0",
    padding: "6px 8px",
    lineHeight: "16px",
    border: "none",
    background: "transparent",
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    }
  },
  [`& .${classes.spacer}`]: {
    marginBottom: "10px"
  },
  [`& .${classes.formLabel}`]: {
    fontWeight: "600",
    color: "#5e5e5e",
    padding: "7px 10px 7px 0",
    lineHeight: "16px"
  },
  [`& .${classes.btn}`]: {
    width: "100%",
    height: "42px",
    padding: "0 15px",
    border: "1px solid",
    borderRadius: "3px",
    // marginTop: "10px"
    margin: "1rem"
  },
  [`& .${classes.blue}`]: {
    color: "#fff",
    backgroundColor: "#007dc1",
    background: "linear-gradient(#007dc1,#0073b2)",
    borderColor: "#004b75 #004b75 #00456a",
    boxShadow: "0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1)"
  },
  [`& .${classes.grey}`]: {
    color: "#fff",
    backgroundColor: "#ccc",
    background: "linear-gradient(#ccc,#aaa)",
    borderColor: "#8a8a8a #8a8a8a #868686",
    boxShadow: "0 1px 0 rgba(0,0,0,.15), inset 0 1px 0 0 hsla(0,0%,100%,.1)"
  },
  [`& .${classes.disabled}`]: {
    background: '#ddd',
    borderColor: '#bbb'
  },
  [`& .${classes.buttonRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  [`& .${classes.buttonBox}`]: {
    display: 'flex',
    position: 'relative',
    width: '100%'
  },
  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  [`& .${classes.logoWrap}`]: {
    padding: "30px 90px 25px",
    position: "relative",
    border: "solid 0px",
    borderColor: "#ddd",
    borderBottomWidth: "1px"
  },
  [`& .${classes.logo}`]: {
    maxWidth: "200px",
    maxHeight: "60px",
    margin: "auto",
    display: "block"
  },
  [`& .${classes.heading}`]: {
    fontSize: "15px",
    lineHeight: "1.5",
    marginTop: "10px",
    textAlign: "center",
    textTransform: "none",
    fontWeight: "600",
    color: "#5e5e5e"
  },
  [`& .${classes.help}`]: {
    color: "#777",
    fontSize: '14px',
    textDecoration: 'none',
    "&.hover": {
      textDecoration: "underline"
    }
  },
  [`& .${classes.checkGroup}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.checkbox}`]: {
    textAlign: 'right',
    marginRight: '10px'
  }
});

const emailRegexp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

const MagicLogin = React.memo(() => {
  const navigate = useNavigate();
  const { linkId } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const [rememberMe, setRememberMe] = useState(false)
  const [errors, setErrors] = useState({
    firstName: false,
    email: false,
    passcode: false,
  });
  const [start2FA, setStart2FA] = useState(false);
  const [requirePasscode, setRequirePasscode] = useState(false);
  const [emailAuthCode, setEmailAuthCode] = useState('');
  const [connected, setConnected] = useState(false)
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resent, setResent] = useState(false)
  const [error, setError] = useState();
  const [invalidFields, setInvalidFields] = useState(new Set());
  const [requiredFields, setRequiredFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    passcode: false,
  })
  const [redirectData, setRedirectData] = useState()
  const [credentials, setCredentials] = useLocalStorage(`magicLink${linkId}`)

  const sendMessageRef = useRef();
  const emailRef = useRef();
  const rememberRef = useRef();
  const codeInputRef = useRef()

  const STATIC_OPTIONS = useMemo(() => ({
    onOpen: () => {
      const message = {
        action: 'connection',
        clientTime: Date.now(),
        email: emailRef.current
      }
      sendMessageRef.current(JSON.stringify(message));
    },
    onError: console.log,
    shouldReconnect: (closeEvent) => {
      console.log('WS CLOSED', closeEvent)
      // return !(closeEvent.code === 1000 || closeEvent.code === 1006)
      return true
    }
  }), []);
  const { sendMessage, lastMessage } = useWebSocket(`wss://${config.ws.magicLinkWS}?linkId=${linkId}`, STATIC_OPTIONS);
  sendMessageRef.current = sendMessage;

  useEffect(() => {
    if (lastMessage !== null) {
      console.log(lastMessage);
      const message = JSON.parse(lastMessage.data)
      if (message.token) {
        if (rememberRef.current) setCredentials({ ...message, exp: Date.now() + 28800000 }) // 8 HOURS
        setRedirectData({ ...message })
      } else if (message.hasOwnProperty('requirePasscode')) {
        setRequirePasscode(!!message.requirePasscode)
        setConnected(true)
      } else if (message.mandrill) {
        console.log('mandrill msg:', message.mandrill)
        // look at response
        if (message.mandrill.error) {
          setError("Error Sending Email")
          console.log('err w/ mandrill:', message.mandrill.error);
        } else {
          setStart2FA(true);
        }
      } else if (message.error) {
        // handle the error
        setError(message?.error?.name || message?.error || "Unexpected Error Occured!");
      }
      setResent(false)
      setIsLoading(false)
      // another response 'registered' (for loading screen)
      // will get mandrill response, look for .mandrill, look at response to check if it actually sent, when that's in place
    }
  }, [lastMessage, navigate])

  useEffect(() => {
    if (connected) {
      if (credentials) {
        if (credentials?.token && credentials?.exp < Date.now() + 28800000) { // 8 HOURS
          rememberRef.current = true
          setRedirectData({ ...credentials })
        } else {
          clearLocalStorage([`magicLink${linkId}`])
          setLoaded(true)
        }
      } else {
        setLoaded(true)
      }
    }
  }, [connected, credentials, linkId])

  useEffect(() => {
    if (redirectData && (rememberRef.current ? credentials : true)) {
      if (redirectData.sessionId) {
        window.open(`https://${config.vsrUrl}/session/${redirectData.session}?id=${redirectData.token}&cid=${redirectData.sessionId}`, '_self')
      } else {
        navigate(`/client/${redirectData.session || `project/${redirectData.project}`}?id=${redirectData.token}`);
      }
    }
  }, [redirectData, credentials])

  useEffect(() => {
    rememberRef.current = rememberMe
  }, [rememberMe])

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    emailRef.current = email

    // Check for required fields
    const required = {
      firstName: firstName.trim().length === 0,
      lastName: lastName.trim().length === 0,
      email: email.trim().length === 0,
      passcode: requirePasscode && passcode.trim().length === 0
    }

    setRequiredFields(required)

    if (Object.values(required).some(v => v)) {
      console.log('required fields are empty')
      setError('Required fields are empty')
      return;
    }

    // Check if valid email
    if (invalidFields.has('email') || !emailRegexp.test(email)) {
      setError("A Valid email is required!")
      return;
    }

    setError();
    setIsLoading(true);
    sendMessageRef.current(JSON.stringify({
      action: 'register',
      firstName,
      lastName,
      email,
      passcode
    }))
  }, [firstName, lastName, email, passcode, sendMessage])

  const handleVerify = useCallback((e) => {
    e.preventDefault();
    setError();
    setIsLoading(true);
    sendMessageRef.current(JSON.stringify({
      action: 'validate',
      email,
      code: emailAuthCode
    }))
  }, [email, emailAuthCode])

  const handleResend = useCallback((e) => {
    e.preventDefault();
    if (codeInputRef.current.textInput[0]) codeInputRef.current.textInput[0].focus()
    codeInputRef.current.state.input.forEach((v, i, a) => a[i] = '')
    setEmailAuthCode();
    setError();
    setResent(true);
    sendMessageRef.current(JSON.stringify({
      action: 'resend',
      email
    }))
  }, [email])

  const handleRememberChange = useCallback((event) => {
    event.currentTarget.blur()
    setRememberMe(event.target.checked)
  }, []);

  const setInvalid = useCallback((field, invalid) => {
    setInvalidFields(prev => {
      const newInvalid = new Set(prev)
      invalid ? newInvalid.add(field) : newInvalid.delete(field)
      return newInvalid
    })
  }, []);

  return loaded ? (
    <Root>
      <main className={classes.main}>
        <div className={classes.logoWrap}>
          <img src={seasiLogo} className={classes.logo} />
        </div>
        {start2FA ? <>
          <div className={classes.formWrap}>
            {error && <>
              <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>An Error has occured: {error}</div>
            </>}
            <h2 className={classes.heading}>Verify your email</h2>
            <>
              <p style={{ textAlign: "center" }}>We've sent a verification code to your email. Please enter it here to proceed.</p>
              <div className={classes.spacer}>
                <div className={classes.formLabel}>
                  <label>Enter Code:</label>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReactCodeInput ref={codeInputRef} type='number' fields={6} onChange={(code) => setEmailAuthCode(code)} />
                </div>
              </div>
              <div className={classes.buttonRow}>
                <div className={classes.buttonBox}>
                  <Button className={clsx(classes.btn, classes.blue, { [classes.disabled]: !!isLoading })} disabled={isLoading || resent} onClick={handleVerify}>Verify</Button>
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <div className={classes.buttonBox}>
                  <Button className={clsx(classes.btn, classes.grey, { [classes.disabled]: !!resent })} disabled={isLoading || resent} onClick={handleResend}>Resend</Button>
                  {resent && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </>
          </div>
        </> :
          <div className={classes.formWrap}>
            <h2 className={classes.heading}>Welcome to virtuworks™ Live</h2>
            <>
              <p style={{ textAlign: "center" }}>Please provide your name and email to sign in.</p>
              <div className={classes.spacer}>
                {error && <>
                  <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>An Error has occured: {error}</div>
                </>}
                <div className={classes.formLabel}>
                  <label>First name</label>
                </div>
                {/* <div className={`${classes.inputWrap} ${errors.firstName ? classes.error : ''}`}>
                  <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className={classes.input} />
                </div> */}
                <StandardField
                  type="text"
                  fieldKey="firstName"
                  value={firstName}
                  onChange={(e, v) => {
                    setFirstName(v)
                  }}
                  placeholder="First Name"
                  required={requiredFields.firstName}
                  invalid={invalidFields.has("firstName")}
                  invalidate={setInvalid}
                  fullWidth={true}
                />
              </div>
              <div className={classes.spacer}>
                <div className={classes.formLabel}>
                  <label>Last name</label>
                </div>
                <StandardField
                  type="text"
                  fieldKey="lastName"
                  value={lastName}
                  onChange={(e, v) => {
                    setLastName(v)
                  }}
                  placeholder="Last Name"
                  required={requiredFields.lastName}
                  invalid={invalidFields.has("lastName")}
                  invalidate={setInvalid}
                  fullWidth={true}
                />
              </div>
              <div className={classes.spacer}>
                <div className={classes.formLabel}>
                  <label>Email</label>
                </div>
                <StandardField
                  type="text"
                  fieldKey="email"
                  value={email}
                  onChange={(e, v) => {
                    setEmail(v)
                  }}
                  placeholder="Email"
                  required={requiredFields.email}
                  regexp={emailRegexp}
                  invalid={invalidFields.has("email")}
                  invalidate={setInvalid}
                  fullWidth={true}
                />
              </div>
              {requirePasscode && <div className={classes.spacer}>
                <div className={classes.formLabel}>
                  <label>Passcode</label>
                </div>
                <StandardField
                  type="text"
                  fieldKey="passcode"
                  value={passcode}
                  onChange={(e, v) => {
                    setPasscode(v)
                  }}
                  placeholder="Passcode"
                  required={requiredFields.passcode}
                  invalid={invalidFields.has("passcode")}
                  invalidate={setInvalid}
                  fullWidth={true}
                  prefix={false}
                  suffix={false}
                />
              </div>}
              <div className={classes.spacer}>
                <div className={classes.checkGroup}>
                  <div className={classes.checkbox}>
                    <Checkbox size="small" color="primary" checked={rememberMe} onChange={handleRememberChange} />
                  </div>
                  <Typography>Remember me on this device for 8 hours</Typography>
                </div>
              </div>
              <div className={classes.buttonBox}>
                <Button className={clsx(classes.btn, classes.blue, { [classes.disabled]: !!isLoading })} disabled={resent} onClick={handleSubmit}>Sign In</Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </>
            {/* <div style={{ marginTop: "2em" }}>
            <a href="#" className={classes.help}>Need help signing in?</a>
          </div> */}
          </div>}
      </main>
    </Root>
  ) : <LoadingPage />;
})

export default MagicLogin;